export const htmlENContent = `<p style="line-height:normal;margin:0cm;text-align:center;"><span style="color:black;font-family:Calibri, sans-serif;font-size:22px;"><strong>Hoi An GO Terms &amp; Conditions May 2024</strong></span></p>
<p style="line-height:normal;margin:0cm;text-align:center;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;text-align:center;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><span style="font-family:&quot;Times New Roman&quot;,serif;font-size:13.0pt;"></span></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Welcome to Hoi An GO Tours. These Terms and Conditions ("Terms") govern your use of our services, including Hoi An GO Adventure Tours, Hoi An GO Experience Tours, and Hoi An GO E-Taxi. By using our services, you agree to be bound by these Terms. Please read them carefully.<span>&nbsp;</span></span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><span style=""></span></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><span style=""></span></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>1. Service Provision</strong></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">1.1. Hoi An GO provides electric taxi services and tours through our mobile application and website platform.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">1.2. Our services are available for booking electric taxis for transportation within designated service areas.&nbsp;</span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>2. Booking and Payment</strong></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.1. Users can book Tours and E-Taxis through our mobile application, website, or directly with drivers. <strong>HOTLINE&nbsp;</strong>1900996690</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.2</span><span style="font-family:Calibri, sans-serif;font-size:11pt;">.&nbsp;</span><span style="font-family:Calibri, sans-serif;font-size:11pt;">Payment for<span>&nbsp;</span>services can be made in cash, </span><span style="font-family:Calibri, sans-serif;font-size:10.5pt;">VNPay (Vietnamese card only)</span><span style="font-family:Calibri, sans-serif;font-size:11pt;"> or PayPal on our website or mobile app&nbsp;</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.3. All fares are displayed and charged in VND or USD. Additional charges may apply for special requests or services.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.4. Locations must be specified during booking.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.5. Drivers cannot give out discounts.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.6. Tips are not included and are at the customer's discretion.</span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>3. Hoi An GO Adventure Tours</strong></span></p>
<ul style="padding-left:48px;">
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Adventure tours operate from 07:00 to 22:00, 7 days a week</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Adventure tours can be booked for 60 minutes or 90 minutes. The 90-minute tours allow for photo stops and short walks around the area</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Each of our Adventure Tours can be booked as part of a Combo Tour</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">The price of the tour and any pick-up fees are established before the start of the tour</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">The start and end point of Adventure Tours is in the old town, of Hoi An</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Pick-up from your hotel can be arranged:</span></p>
        <ul style="padding-left:48px;">
            <li>
                <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">60-minute tour: Pick-up fee calculated by distance</span></p>
            </li>
            <li>
                <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">90-minute tour and more: Pick-up free of charge</span></p>
            </li>
        </ul>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Entrance tickets are not included in the tour cost and are to be paid in cash by the customer.</span></p>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;">&nbsp;</p>
    </li>
</ul>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>4. Hoi An GO Experience Tours</strong></span></p>
<ul style="padding-left:48px;">
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Experience Tour start times are flexible, from 06:00 to 16:00, 7 days a week</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Each Experience tour is a 3-hour tour</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Pick-up and drop-off from a preferred location are included within a specified km range</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">A local guide throughout the tour is included in the fee</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Entrance tickets, snacks, and drinks are included in the tour cost</span></p>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;">&nbsp;</p>
    </li>
</ul>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>5. Hoi An GO E-Taxi</strong></span></p>
<ul style="padding-left:48px;">
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">All journeys are calculated by distance in KM and the number of passengers in the vehicle</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">All prices are confirmed prior to your journey and are calculated using our App</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">You can make a payment directly to your driver either by cash (VND and USD accepted) or by VNPay.</span></p>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;">&nbsp;</p>
    </li>
</ul>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>6. Additional Extras</strong></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Tour Guide: 500,000 VND per tour.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Photographer: 500,000 VND per tour, including digital images.</span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>7. Child Policy</strong></span></p>
<ul style="padding-left:48px;">
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Under 3 years old: Free of charge.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">3 years old and above: Full price</span></p>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;">&nbsp;</p>
    </li>
</ul>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>8. Cancellation Policy</strong></span></p>
<ul style="padding-left:48px;">
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">More than 48 hours before the tour starts: Full refund.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">48 to 24 hours before the tour starts: 50% refund.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Within 24 hours before the tour starts or no show: No refund.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Hoi An GO reserves the right to cancel bookings in extreme weather conditions for safety reasons.&nbsp;</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Cancellations during the trip are not accepted.</span></p>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;">&nbsp;</p>
    </li>
</ul>
<p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">&nbsp; &nbsp;8.1. Cancellations are subject to our cancellation policy, which may vary based on the booking type.</span></p>
<p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">&nbsp; &nbsp;8.2<strong>.</strong> Refunds, if applicable, will be processed according to our refund policy and will be made within 14 days</span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>9. User Responsibilities</strong></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">9.1. Users are responsible for providing accurate and up-to-date information for booking purposes.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">9.2. Users are responsible for their conduct during the use of our services and must comply with all relevant laws and regulations.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">9.3. Users must not engage in any unlawful or unauthorised activities using our services.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">9.4. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device. You agree to accept responsibility for all activities that occur under your account or password.</span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>10. Safety</strong></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">10.1. Our drivers' main priority is to always ensure your safety. The driver reserves the right to refuse customers who display unsafe/rude behaviour or are intoxicated.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">10.2. Special requests can be discussed with the driver. The driver has the right to decline requests.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">10.3. If an Emergency occurs during a tour contact us immediately at +84 (0)905663616</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">10.4. All passengers travelling with Hoi An GO are covered by our civil liability insurance</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">10.5. All Hoi An GO vehicles are checked daily and undergo regular maintenance</span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><span style="color:fuchsia;"></span></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>11. Liability</strong></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">11.1. Hoi An GO is not liable for any loss, damage, injury, or inconvenience arising from the use of our services.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">11.2. Users agree to indemnify and hold harmless Hoi An GO and its affiliates from any claims, damages, losses, liabilities, and expenses.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>12. Privacy</strong></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">12.1. Our Privacy Policy governs the collection, use, and storage of user information. By using our services, you consent to the terms of our Privacy Policy.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">12.2. We may collect and use your location data to provide certain features and services. By using our Website, you consent to our collection and use of location data as described in our Privacy Policy.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">12.3. Purpose of Location Data Collection</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Location data is used to enhance your experience on our Website, such as providing location-specific services, content, and advertisements.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">12.4. Sharing of Location Data</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">We will not share your location data with third parties except as described in our Privacy Policy.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>13. Modifications</strong></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">13.1. Hoi An GO reserves the right to modify these Terms and Conditions at any time without prior notice. Changes will be effective immediately upon posting on our website.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">13.2. Users are responsible for regularly reviewing these Terms and Conditions. Continued use of our services after any such changes constitutes acceptance of the modified Terms and Conditions.</span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>14. Governing Law</strong></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">14.1. These Terms and Conditions shall be governed by and construed in accordance with the laws of the Socialist Republic of Vietnam.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>15. Contact Us</strong></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">If you have any questions about these Terms and Conditions, please contact us at </span><a href="mailto:xinchao@hoiango.com"><span style="color:#1155CC;font-family:Calibri, sans-serif;font-size:11pt;">xinchao@hoiango.com</span></a><span style="font-family:Calibri, sans-serif;font-size:11pt;">.</span></p>`;
