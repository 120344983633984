export const htmlVIContent = `<p style="line-height:normal;margin:0cm;text-align:center;"><span style="color:black;font-family:Calibri, sans-serif;font-size:22px;"><strong>Điều khoản và Điều kiện của Hoi An GO (Tháng 5, 2024)</strong></span></p>
<p style="line-height:normal;margin:0cm;text-align:center;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;text-align:center;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><span style="font-family:&quot;Times New Roman&quot;,serif;font-size:13.0pt;"></span></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Chào mừng đến với Hoi An GO Tours. Những Điều khoản và Điều kiện ("Điều khoản") dưới đây quy định việc sử dụng dịch vụ của Hoi An GO, bao gồm dịch vụ Tours Khám phá, dịch vụ Tours Trải nghiệm, và dịch vụ Trung chuyển &amp; E-Taxi. Với việc sử dụng dịch vụ của chúng tôi, bạn đồng ý với các Điều khoản dưới đây. Vui lòng đọc kỹ.</span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><span style=""></span></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><span style=""></span></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>1. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Cung cấp dịch vụ</strong></span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">1.1. </span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Hoi An GO cung cấp dịch vụ taxi điện và tour du lịch thông qua ứng dụng di động và nền tảng trang web của chúng tôi.</span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">1.2. </span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Dịch vụ taxi điện của chúng tôi phục vụ vận chuyển trong các khu vực được chỉ định sẵn thuộc thành phố Hội An, tỉnh Quảng Nam.</span></span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>2. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Đặt chỗ và Thanh toán</strong></span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.1. </span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Người dùng có thể đặt Tour và E-Taxi thông qua ứng dụng di động, trang web của chúng tôi, hoặc trực tiếp với tài xế. HOTLINE 1900996690.</span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.2</span><span style="font-family:Calibri, sans-serif;font-size:11pt;">.&nbsp;</span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Thanh toán cho dịch vụ có thể được thực hiện bằng tiền mặt, VNPay (chỉ thẻ Việt Nam) hoặc PayPal trên trang web hoặc ứng dụng di động của chúng tôi. Các phương thức thanh toán khác, nếu có, sẽ được cập nhật trên các kênh thông tin của Hoi An GO.</span></span><span style="font-family:Calibri, sans-serif;font-size:11pt;">&nbsp;</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.3. </span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Tất cả giá cước được hiển thị và tính bằng VND hoặc USD. Các khoản phí bổ sung có thể áp dụng cho các yêu cầu hoặc dịch vụ đặc biệt.</span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.4. </span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Địa điểm phải được chỉ định trong quá trình đặt hàng dịch vụ.</span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.5. </span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Tài xế sẽ không được phép giảm giá.</span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">2.6. </span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Tiền tip không bao gồm trong giá bán và tùy thuộc vào quyết định của khách hàng.</span></span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>3. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Tour Khám phá Hoi An GO</strong></span></span></p>
<ul style="padding-left:48px;">
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Tour Khám phá&nbsp; hoạt động từ 07:00 đến 22:00, trong 7 ngày một tuần.</span></span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Tour Khám phá có thể được đặt với thời lượng 60 phút hoặc 90 phút. Tour 90 phút cho phép có thêm thời gian để dừng chụp ảnh và đi bộ ngắn quanh khu vực.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Mỗi Tour Khám phá có thể được đặt như một phần của Combo Tour.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Giá tour và các phí đón/trả khách được xác định trước khi bắt đầu tour.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Điểm khởi hành và kết thúc của Tour Khám phá là ở khu phố cổ Hội An.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Đón khách từ khách sạn của bạn có thể được sắp xếp như sau:&nbsp;</span></p>
        <ul style="padding-left:48px;">
            <li>
                <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Times New Roman&quot;,serif;font-size:7.0pt;"><span>&nbsp;</span></span><span style="font-family:Calibri, sans-serif;font-size:11pt;">Tour thời lượng 60 phút: chi phí đón khách được tính theo quãng đường.</span></p>
            </li>
            <li>
                <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Tour thời lượng 90 phút và hơn: Đón miễn phí.&nbsp;</span></p>
            </li>
        </ul>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Vé vào cổng không bao gồm trong chi phí tour và khách hàng phải trả bằng tiền mặt hoặc phương thức phù hợp.</span></span></p>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;">&nbsp;</p>
    </li>
</ul>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>4. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Hoi An GO Tour Trải nghiệm</strong></span></span></p>
<ul style="padding-left:48px;">
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Giờ khởi hành của Tour Trải nghiệm linh hoạt, từ 06:00 đến 16:00, 7 ngày một tuần.</span></span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Mỗi Tour Trải nghiệm kéo dài 3 giờ.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Đón và trả khách từ địa điểm ưa thích trong phạm vi quãng đường được chỉ định.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Hướng dẫn viên địa phương đi suốt tour được bao gồm trong giá của tour.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Vé vào cổng, đồ ăn nhẹ và nước uống được bao gồm trong chi phí tour.</span></span></p>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;">&nbsp;</p>
    </li>
</ul>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>5. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Hoi An GO E-Taxi</strong></span></span></p>
<ul style="padding-left:48px;">
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Tất cả hành trình được tính theo khoảng cách tính bằng KM và số lượng hành khách trên xe.</span></span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Giá của mỗi chuyến đi sẽ được xác nhận trước khi khởi hành và được tính toán thông qua ứng dụng HoiAnGO của chúng tôi.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Bạn có thể thanh toán trực tiếp cho tài xế bằng tiền mặt (chấp nhận VND và USD) hoặc bằng VNPay, hoặc các phương thức khác, nếu có, trên ứng dụng ở thời điểm sử dụng.</span></span></p>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;">&nbsp;</p>
    </li>
</ul>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>6. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Các Dịch vụ Bổ sung</strong></span></span></p>
<ul>
    <li>
        <p style="line-height:normal;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Hướng dẫn viên: 500.000 VND mỗi tour.</span></span></p>
    </li>
    <li>
        <p style="line-height:normal;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Dịch vụ chụp ảnh chuyên nghiệp: 500.000 VND mỗi tour, bao gồm ảnh kỹ thuật số.</span></span></p>
    </li>
</ul>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>7. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Chính sách Trẻ em</strong></span></span></p>
<ul style="padding-left:48px;">
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Dưới 3 tuổi: Miễn phí.</span></span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">3 tuổi trở lên: Giá như người lớn.</span></span></p>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;">&nbsp;</p>
    </li>
</ul>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>8. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Chính sách hủy Tour</strong></span></span></p>
<ul style="padding-left:48px;">
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Trước hơn 48 giờ trước khi tour bắt đầu: Hoàn tiền toàn bộ.</span></span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Từ 48 đến 24 giờ trước khi tour bắt đầu: Hoàn tiền 50%.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Trong vòng 24 giờ trước khi tour bắt đầu hoặc không có mặt: Không hoàn tiền.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">Hoi An GO có quyền hủy đặt chỗ trong điều kiện thời tiết khắc nghiệt vì lý do an toàn.</span></p>
    </li>
    <li>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Không chấp nhận hủy khi đang trong chuyến đi.</span></span></p>
        <p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;">&nbsp;</p>
    </li>
</ul>
<p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">&nbsp; &nbsp;8.1. </span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Việc hủy bỏ phụ thuộc vào chính sách hủy bỏ của chúng tôi, có thể thay đổi tùy theo loại chuyến, tour.</span></span></p>
<p style="line-height:normal;margin-bottom:0cm;margin-right:0cm;margin-top:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">&nbsp; &nbsp;8.2<strong>.</strong> </span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Việc hoàn tiền, nếu có, sẽ được xử lý theo chính sách hoàn tiền của chúng tôi và sẽ được thực hiện trong vòng 14 ngày.</span></span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>9. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Trách nhiệm của Người dùng</strong></span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">9.1. </span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Người dùng có trách nhiệm cung cấp thông tin chính xác và cập nhật cho mục đích đặt chuyến, tour.</span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">9.2. Người dùng có trách nhiệm về hành vi của mình trong quá trình sử dụng dịch vụ của chúng tôi và phải tuân thủ tất cả các luật và quy định liên quan.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">9.3. Người dùng không được tham gia vào bất kỳ hoạt động bất hợp pháp hoặc không được phép nào khi sử dụng dịch vụ của chúng tôi.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">9.4. Bạn có trách nhiệm duy trì tính bảo mật của tài khoản và mật khẩu của mình. Bạn đồng ý chấp nhận trách nhiệm cho tất cả các hoạt động xảy ra dưới tài khoản hoặc mật khẩu của bạn.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>10. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>An toàn</strong></span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">10.1. Ưu tiên hàng đầu của tài xế chúng tôi luôn là đảm bảo an toàn cho bạn. Tài xế có quyền từ chối khách hàng có hành vi không an toàn, thô lỗ hoặc say xỉn.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">10.2. Các yêu cầu đặc biệt có thể được thảo luận với tài xế. Tài xế có quyền từ chối các yêu cầu.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">10.3. Nếu có trường hợp khẩn cấp xảy ra trong chuyến đi hay tour, liên hệ ngay với chúng tôi qua số +84 (0)905663616.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">10.4. Tất cả hành khách đi cùng Hoi An GO đều được bảo hiểm trách nhiệm dân sự của chúng tôi.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">10.5. Tất cả các phương tiện của Hoi An GO đều được kiểm tra hàng ngày và bảo trì định kỳ.</span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><span style="color:fuchsia;"></span></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>11. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Trách nhiệm pháp lý</strong></span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">11.1. Hoi An GO không chịu trách nhiệm cho bất kỳ mất mát, thiệt hại, thương tích hoặc bất tiện nào phát sinh từ việc sử dụng dịch vụ của chúng tôi.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">11.2. Người dùng đồng ý bồi thường và giữ cho Hoi An GO và các đối tác của mình không bị ảnh hưởng bởi bất kỳ khiếu nại, thiệt hại, mất mát, trách nhiệm và chi phí nào.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>12. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Quyền riêng tư</strong></span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">12.1. Chính sách quyền riêng tư của chúng tôi điều chỉnh việc thu thập, sử dụng và lưu trữ thông tin người dùng. Bằng cách sử dụng dịch vụ của chúng tôi, bạn đồng ý với các điều khoản của Chính sách quyền riêng tư của chúng tôi.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">12.2. Chúng tôi có thể thu thập và sử dụng dữ liệu vị trí của bạn để cung cấp một số tính năng và dịch vụ. Bằng cách sử dụng trang web của chúng tôi, bạn đồng ý với việc thu thập và sử dụng dữ liệu vị trí như được mô tả trong Chính sách quyền riêng tư của chúng tôi.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">12.3. Mục đích thu thập dữ liệu vị trí:</span><br><span style="font-family:Calibri, sans-serif;font-size:11pt;">Dữ liệu vị trí được sử dụng để nâng cao trải nghiệm của bạn trên trang web của chúng tôi, chẳng hạn như cung cấp dịch vụ, nội dung và quảng cáo theo vị trí cụ thể.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">12.4. Chia sẻ dữ liệu vị trí:</span><br><span style="font-family:Calibri, sans-serif;font-size:11pt;">Chúng tôi sẽ không chia sẻ dữ liệu vị trí của bạn với bên thứ ba trừ khi được mô tả trong Chính sách quyền riêng tư của chúng tôi.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>13. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Sửa đổi</strong></span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">13.1. Hoi An GO có quyền sửa đổi các Điều khoản và Điều kiện này bất cứ lúc nào mà không cần thông báo trước. Các thay đổi sẽ có hiệu lực ngay khi được đăng trên trang web của chúng tôi.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">13.2. Người dùng có trách nhiệm thường xuyên xem xét các Điều khoản và Điều kiện này. Việc tiếp tục sử dụng dịch vụ của chúng tôi sau bất kỳ thay đổi nào đều đồng nghĩa với việc chấp nhận các Điều khoản và Điều kiện đã được sửa đổi.</span></p>
<p style="line-height:normal;margin:0cm;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>14. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Luật pháp điều chỉnh</strong></span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:Calibri, sans-serif;font-size:11pt;">14.1. Các Điều khoản và Điều kiện này sẽ được điều chỉnh và giải thích theo luật pháp của Cộng hòa Xã hội Chủ nghĩa Việt Nam.</span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;">&nbsp;</p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"></span></p>
<p style="line-height:normal;margin:0cm;"><span style="font-family:Calibri, sans-serif;font-size:11pt;"><strong>15. </strong></span><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;"><strong>Liên hệ với chúng tôi</strong></span></span></p>
<p style="line-height:normal;margin:0cm 0cm 0cm 40px;"><span style="font-family:&quot;Calibri&quot;,sans-serif;font-size:11.0pt;"><span style="line-height:107%;">Nếu bạn có bất kỳ câu hỏi nào về các Điều khoản và Điều kiện này, vui lòng liên hệ với chúng tôi qua email</span></span><span style="font-family:Calibri, sans-serif;font-size:11pt;"> </span><a href="mailto:xinchao@hoiango.com"><span style="color:#1155CC;font-family:Calibri, sans-serif;font-size:11pt;">xinchao@hoiango.com</span></a><span style="font-family:Calibri, sans-serif;font-size:11pt;">.</span></p>`;
