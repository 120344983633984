import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { htmlENContent } from './enTerms';
import { htmlVIContent } from './viTerms';

type Props = {
  locale?: string;
};

export default function Terms({ locale }: Props) {
  return (
    <Container>
      <TermsWrap>
        <div
          dangerouslySetInnerHTML={{
            __html: locale === 'vi' ? htmlVIContent : htmlENContent,
          }}
        />
      </TermsWrap>
    </Container>
  );
}

const TermsWrap = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '32px 24px 24px 24px',
  borderRadius: '16px',
  backgroundColor: theme.palette.white.main,
  lineHeight: '22px',

  ul: {
    margin: 0,
  },

  'p > span': {
    lineHeight: '24px',
  },
}));

const Container = styled(Box)(({ theme }) => ({
  paddingTop: '12px',
  paddingBottom: '12px',
}));
